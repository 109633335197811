import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        font-family: 'Nunito Sans';
        max-width: 100%;
      }

      * {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
  }

  #container-mfe {
    z-index: -1;
    width: 100%;
  }
`;
